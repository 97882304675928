import Vue from 'vue'
import VueRouter from 'vue-router'
import Layout from '@/views/layout/index.vue'
import Login from '@/views/login/index.vue'

// import Home from "@/views/home/index.vue"
// const originalPush = VueRouter.prototype.push;
// VueRouter.prototype.push = function push(location) {
//   return originalPush.call(this, location).catch((err) => err);
// };
Vue.use(VueRouter)

const routes = [
  {
    id: '100',
    path: '/base',
    name: 'Base',
    component: Layout,
    title: '基础数据',
    icon: 'el-icon-s-data',
    hidden: false,
    redirect: '/base/project',
    children: [
      {
        id: '101',
        path: '/base/project',
        title: '项目信息',
        name: 'Home',
        icon: 'el-icon-menu',
        meta: { keepAlive: false },
        hidden: false,
        component: () => import('@/views/base/project')
      },
      {
        id: '102',
        path: '/base/collect',
        title: '消纳场信息',
        name: 'Home',
        icon: 'el-icon-menu',
        meta: { keepAlive: false },
        hidden: false,
        component: () => import('@/views/base/collect')
      },
      {
        id: '103',
        path: '/base/cars',
        title: '车队信息',
        name: 'Home',
        icon: 'el-icon-menu',
        meta: { keepAlive: false },
        hidden: false,
        component: () => import('@/views/base/cars')
      },
      {
        id: '104',
        path: '/base/car',
        title: '车辆信息',
        name: 'Home',
        icon: 'el-icon-menu',
        meta: { keepAlive: false },
        hidden: false,
        component: () => import('@/views/base/car')
      },
      {
        id: '105',
        path: '/base/dispose',
        title: '处置证信息',
        name: 'Home',
        icon: 'el-icon-menu',
        meta: { keepAlive: false },
        hidden: false,
        component: () => import('@/views/base/dispose')
      },
      {
        id: '106',
        path: '/base/grant',
        title: '准运证管理',
        name: 'Home',
        icon: 'el-icon-menu',
        meta: { keepAlive: false },
        hidden: false,
        component: () => import('@/views/base/grant')
      }
    ]
  },
  {
    id: '200',
    path: '/center',
    name: 'Center',
    component: Layout,
    title: '数据中心',
    icon: 'el-icon-s-grid',
    hidden: false,
    redirect: '/center/order',
    children: [
      {
        id: '201',
        path: '/center/super',
        title: '超速数据',
        name: 'Home',
        icon: 'el-icon-s-platform',
        meta: { keepAlive: false },
        hidden: false,
        component: () => import('@/views/center/super')
      },
      {
        id: '202',
        path: '/center/unusual',
        title: '异常事件表',
        name: 'Home',
        icon: 'el-icon-s-order',
        meta: { keepAlive: false },
        hidden: false,
        component: () => import('@/views/center/unusual')
      },
      {
        id: '203',
        path: '/center/order',
        title: '工单数据',
        name: 'Home',
        icon: 'el-icon-s-order',
        meta: { keepAlive: false },
        hidden: false,
        component: () => import('@/views/center/order')
      },
      {
        id: '205',
        path: '/center/recycleOrder',
        title: '消纳场工单',
        name: 'Home',
        icon: 'el-icon-s-order',
        meta: { keepAlive: false },
        hidden: false,
        component: () => import('@/views/center/recycleOrder')
      },
      {
        id: '206',
        path: '/center/fenceTriggerRecord',
        title: '电子围栏触发纪录',
        name: 'Home',
        icon: 'el-icon-guide',
        meta: { keepAlive: false },
        hidden: false,
        component: () => import('@/views/center/fenceTriggerRecord')
      },
      {
        id: '204',
        path: '/center/seaFreight',
        title: '船运数据',
        name: 'Home',
        icon: 'el-icon-ship',
        meta: { keepAlive: false },
        hidden: false,
        component: () => import('@/views/center/seaFreight')
      }
    ]
  },
  // {
  //   id: '35',
  //   path: '/common',
  //   name: 'Common',
  //   component: Layout,
  //   hidden: true,
  //   children: [
  //     {
  //       id: '10',
  //       path: '/common/emptyPage',
  //       title: '空白页面',
  //       name: 'EmptyPage',
  //       icon: 'el-icon-s-home',
  //       hidden: true,
  //       component: () => import(/*webpackChunkName: "emptyPage" */ '@/views/empty-page/index.vue')
  //     }
  //   ]
  // },
  {
    id: '1',
    path: '/',
    redirect: 'login',
    hidden: true
  },
  {
    id: '2',
    path: '/login',
    name: 'Login',
    component: Login,
    hidden: true
  }
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})
// 全局守卫
// router.beforeEach((to, from, next) => {
//   //todos:判断是否有token,有继续跳转，无跳转至登陆页
// });
export default router
export { routes }
